export const promptConfig = [
  { label: 'Key', property: 'key' },
  { label: 'Name', property: 'name' },
  { label: 'Category', property: 'category' },
  { label: 'Context', property: 'context' },
  { label: 'Prompt', property: 'prompt' },
  { label: 'Last Modified By', property: 'modifiedBy' },
  { label: 'Last Modified Date', property: 'modifiedDate' },
  { label: 'Last Run Date', property: 'runDate' },
];

export const runPromptCategories = [
  { label: 'Keywords', value: 'Keywords' },
  { label: 'Description', value: 'Description' },
  { label: 'Classification', value: 'Classification' },
]