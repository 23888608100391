import axios from 'axios'
import apiConfig from '../../config/apiConfig'

const {
  genai: {
    promptsUrl = ''
  } = {},
} = apiConfig

export const fetchPromptData = () => axios.get(`${promptsUrl}`)

