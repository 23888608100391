import React, { useState, startTransition } from 'react';
import { makeStyles } from '@mui/styles'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { DEFAULT_GENAI_MODEL, availableAiModels } from '../../genai/constants/genai';
import { runPromptCategories } from '../config/PromptConfig';
import { convertDateToDisplay } from '../../helpers/dateHelper';

const useStyles = makeStyles({
  promptHeading: {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DDDDDD'
  },
  clearIconStyles: {
    cursor: 'pointer'
  },
  promptFieldsClass: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '30px',
  },
  buttonGroup: {
    display: 'flex',
    gap: '20px',
    padding: '0px 30px',
  },
  additionalInfoStyles: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  infoLabel: {
    color: '#666666',
    fontSize: '14px',
    paddingBottom: '5px'
  }
});

const DrawerPromptDetails = ({
  parentClasses = {},
  mode = 'add',
  onClickClear = () => { },
  setDrawerMode = () => { }
}) => {
  const classes = useStyles()
  const drawerTitle = mode === 'add' ? 'Add Prompt' : 'View Prompt'
  const [name, setName] = useState('')
  const [model, setModel] = useState(DEFAULT_GENAI_MODEL)
  const [category, setCategory] = useState('Keywords')
  const [context, setContext] = useState('')
  const [prompt, setPrompt] = useState('')

  const onSave = () => {
    // Save the prompt based on the mode
  }

  const onClickCancel = () => {
    startTransition(() => {
      setPrompt('')
      setContext('')
      setName('')
    })
  }

  const editPromptDetails = () => {
    setDrawerMode('edit')
  }

  const time = "2024-02-09T09:45:13.827Z"

  return (
    <>
      <div className={classes.promptHeading}>
        <h3>{drawerTitle}</h3>
        <ClearIcon onClick={onClickClear} className={classes.clearIconStyles} />
      </div>
      {(
        <Grid container className={classes.promptFieldsClass}>
          <Grid item>
            <FormControl size="small">
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                style={{ width: '200px', height: '50px' }}
                labelId="category-label"
                id="category-label"
                value={category}
                input={<OutlinedInput label="Category" />}
                onChange={(event) => setCategory(event.target.value)}
                disabled={mode === 'view'}
              >
                {runPromptCategories.map((category) => (
                  <MenuItem value={category.value}>{category.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl size="small">
              <InputLabel id="model-label">Model Used</InputLabel>
              <Select
                style={{ width: '200px', height: '50px' }}
                labelId="model-label"
                id="model-label"
                value={model}
                input={<OutlinedInput label="Model Used" />}
                onChange={(event) => setModel(event.target.value)}
                disabled={mode === 'view'}
              >
                {availableAiModels.map((category) => (
                  <MenuItem value={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              required
              id='name'
              label='Name'
              placeholder='Enter Name'
              InputLabelProps={{ shrink: true }}
              value={name}
              fullWidth
              onChange={(event) => setName(event.target.value)}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id='context-name'
              label='Context'
              placeholder='Enter Context'
              InputLabelProps={{ shrink: true }}
              value={context}
              fullWidth
              multiline
              minRows={4}
              onChange={(event) => setContext(event.target.value)}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="prompt-name"
              label="Prompt"
              placeholder="Enter Prompt"
              InputLabelProps={{ shrink: true }}
              value={prompt}
              fullWidth
              multiline
              minRows={6}
              onChange={(event) => setPrompt(event.target.value)}
              disabled={mode === 'view'}
            />
          </Grid>
          {
            mode !== 'add' && (
              <Grid container className={classes.additionalInfoStyles}>
                <Grid item>
                  <div className={classes.infoLabel}>Last Modified By</div>
                  <div>Bharath</div>
                </Grid>
                <Grid item>
                  <div className={classes.infoLabel}>Last Modified Date</div>
                  <div>{convertDateToDisplay(time)}</div>
                </Grid>
                <Grid item>
                  <div className={classes.infoLabel}>Last Run Date</div>
                  <div>{convertDateToDisplay(time)}</div>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      )}
      {
        mode === 'view' ? (
          <Grid container className={classes.buttonGroup}>
            <Button
              name='edit'
              variant="outlined"
              className={parentClasses.buttonStyle}
              onClick={editPromptDetails}
            >
              <EditIcon fontSize='small' />
              Edit
            </Button>
          </Grid>
        ) : (

          <Grid container className={classes.buttonGroup}>
            <Button
              variant="outlined"
              className={parentClasses.buttonStyle}
              onClick={onSave}
              disabled={
                !(
                  prompt &&
                  category &&
                  model &&
                  prompt
                )
              }
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className={classes.transparentButton}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
          </Grid>
        )
      }
    </>
  );
};

export default DrawerPromptDetails;
