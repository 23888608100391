import React, { useEffect, startTransition } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Drawer } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { promptConfig } from '../config/PromptConfig'
import { fetchPromptData } from '../services/promptService'
import DrawerPromptDetails from './DrawerPromptDetails'

const useStyles = makeStyles({
  tableContainer: {
    padding: '20px 30px',
    width: 'inherit'
  },
  tableHeadingStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableStyles: {
    border: '1px solid #f4f0f0'
  },
  itemDataCell: {
    minWidth: '100px',
    minHeight: '40px',
  },
  seeMoreStyles: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: '#178295',
    cursor: 'pointer'
  },
  buttonStyle: {
    cursor: 'pointer',
    height: '30px',
    color: '#178295',
    background: 'white',
    gap: '5px',
    alignItems: 'flex-end',
    '&:hover': {
      color: 'white',
      background: '#178295',
    },
  },
  promptDrawerStyles: {
    width: '450px',
    backgroundColor: 'white',
    marginLeft: 70,
  },
})

const PromptSettingsPage = () => {
  const classes = useStyles()

  const [promptData, setPromptData] = React.useState([])
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const [drawerMode, setDrawerMode] = React.useState('add')

  useEffect(() => {
    fetchPromptData().then((response) => {
      setPromptData(response.data)
    }).catch((error) => {
      setPromptData([])
    })
  }, [])


  const openAddPromptDialog = () => {
    startTransition(()=> {
      setDrawerMode('add')
      setIsDrawerOpen(true)
    })
  }

  const onClickClear = ()=> {
    setIsDrawerOpen(false)
  }

  const viewMoreDetails = () => {
    startTransition(()=> {
      setDrawerMode('view')
      setIsDrawerOpen(true)
    })
  }


  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer} data-testid='prompt-table-container'>
        <div className={classes.tableHeadingStyles}>
          <h3 data-testid='headingLabel'>Showing {promptData?.length} Prompts</h3>
          <Button
            name='add'
            variant="outlined"
            className={classes.buttonStyle}
            onClick={openAddPromptDialog}
          >
            <AddCircleOutlineOutlinedIcon fontSize='small' />
            Add
          </Button>
        </div>
        <Table className={classes.tableStyles}>
          <TableHead>
            <TableRow>
              {promptConfig.map((config) => (
                <TableCell align='center' key={config.property} id={config.property} className={classes.itemDataCell}>{config.label}</TableCell>
              ))}
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(promptData || []).map((item) => (
              <TableRow key={item.key} data-testid={item.key}>
                {promptConfig.map((config) => (
                  <TableCell key={config.property} className={classes.itemDataCell} align='center'>
                    {item[config.property].length > 50 ? `${item[config.property].substring(0, 50)}...` : item[config.property]}
                  </TableCell>
                ))}
                <TableCell><div className={classes.seeMoreStyles} onClick={()=> viewMoreDetails()}>See More <ReadMoreIcon /></div></TableCell>
              </TableRow>
            ))}
            {promptData?.length === 0 && <div data-testid='norecords'>No Records Found</div>}
          </TableBody>
        </Table>
      </TableContainer>
      {isDrawerOpen && <Drawer
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        classes={{
          paper: classes.promptDrawerStyles,
        }}
        data-testid='drawer-prompt'
      >
        <DrawerPromptDetails mode={drawerMode} onClickClear={onClickClear} setDrawerMode={setDrawerMode} parentClasses={classes}/>
      </Drawer>
      }
    </>
  );
};

export default PromptSettingsPage
